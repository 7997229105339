export const SKILLS = [
    { name: 'acrobatics', ability: 'dex' },
    { name: 'animal handling', ability: 'wis' },
    { name: 'arcana', ability: 'int' },
    { name: 'athletics', ability: 'str' },
    { name: 'deception', ability: 'cha' },
    { name: 'history', ability: 'int' },
    { name: 'insight', ability: 'wis' },
    { name: 'intimidation', ability: 'cha' },
    { name: 'investigation', ability: 'int' },
    { name: 'medicine', ability: 'wis' },
    { name: 'nature', ability: 'int' },
    { name: 'perception', ability: 'wis' },
    { name: 'performance', ability: 'cha' },
    { name: 'persuasion', ability: 'cha' },
    { name: 'religion', ability: 'int' },
    { name: 'slight of hand', ability: 'dex' },
    { name: 'stealth', ability: 'dex' },
    { name: 'survival', ability: 'wis' },
];